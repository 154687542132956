.card {
  margin: 0.5em;

  transition: 0.5s;
}

.cardHeader {
  background-color: white;
}

.container {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.card:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.cardScheduled {
  border: 2px dashed #b0b0b0;
  color: #d2d2d2;
}

.cardAddPicture {
  margin: 0.5em;
  opacity: 0.1;
  transition: 0.5s;
}
.cardAddPicture:hover {
  opacity: 1;
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.cardAddPictureImg {
  margin-left: -1.5em;

  width: 4em;
  height: 4em;
}

.pullDown {
  margin-top: 1em;
}
