.removeBorder {
  border: none;
  outline: none;
}

.removeBorder:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
