.card {
  margin: 0.5em;
  transition: 0.5s;
  width: 17em;
  height: 10rem;
}

.cardHeader {
  background-color: white;
  padding-top: 2em;
}

.container {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.card:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.removeBorder {
  border: none;
  outline: none;
}

.removeBorder:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.cardAddPicture {
  margin: 0.5em;

  width: 17em;
  height: 10rem;
  opacity: 0.1;
  transition: 0.5s;
}
.cardAddPicture:hover {
  opacity: 1;
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}
.listItemAdminUserTypeBadge {
  position: absolute;
  top: 0;
  left: 0;
}

.pullDown {
  margin-top: 1em;
}

.cardAddPictureImg {
  width: 7em;
  height: 7em;
  position: absolute;
  left: 29%;
}

.inActive {
  opacity: 0.5;
}
