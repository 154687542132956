.pullLeft5 {
  padding-left: 0.5em;
}

.textArea {
  margin-top: -1em;
  border-top: none;
  border-radius: 0;
}

.error {
  margin-bottom: 1em;
  color: red;
}
