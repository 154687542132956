.topMenu {
  background-color: white;
  -webkit-box-shadow: 0px 8px 14px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 14px 2px rgba(0, 0, 0, 0.2);
}

.logout {
  padding-left: 30px;
}

.pullRight05 {
  padding-right: 1.5em;
}

.headerImage {
  width: 4em;
  height: 2.5em;
}

.logoutIcon {
  margin: 0;
  padding: 0;
  height: 1.5em;
  margin-left: 1em;
}
