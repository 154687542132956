.title {
  font-size: small;
}

.button {
  padding-top: 15px;
  margin-top: 15px;
}

.clear {
  padding: 0;
  margin: 0;
}

.pushDown1Em {
  padding-bottom: 1em;
}
