.title {
  font-size: small;
}

.button {
  padding-top: 15px;
  margin-top: 15px;
}

.wrongEmailOrPassword {
  color: #a50000;
}

.clearPadding {
  padding-left: 0;
}

.hover {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  text-align: center;
  width: 30%;
}

.bg {
  background-image: url("../../../public/bg2.jpg"); /* Path is relative to the public folder */
  background-size: cover; /* Cover the entire container, maintaining aspect ratio */
  background-position: center; /* Center the image within the container */
  background-repeat: no-repeat; /* Do not repeat the background image */
  height: 100vh; /* Set the height of the container to the full viewport height */
  width: 100vw; /* Set the width of the container to the full viewport width */
}
