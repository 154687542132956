.container {
  margin-top: 0;
  padding-top: 1em;
  display: flex;

  flex-direction: row;
  justify-content: stretch;
}

.card {
  margin: 0.5em;
  transition: 0.5s;
}

.cardHeader {
  background-color: white;
}

.card:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.cardAddPicture {
  margin: 0.5em;
  opacity: 0.1;
  transition: 0.5s;
}
.cardAddPicture:hover {
  opacity: 1;
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.picture {
  width: 1.5em;
  margin-left: -0.5em;
}

.menu {
  margin: 10px auto;
}

.listViewHeader {
  font-weight: bold;
  margin-bottom: 15px;
}

.listViewHeader > div {
  border: 1px solid grey;
}

.listViewItem > div {
  border: 1px solid grey;
}

.pullDown025 {
  padding-top: 0.25em;
}

.pushLeft025 {
  padding: 0;
  margin: 0;
}

.btn {
  padding: 0px;
}

.btn:focus,
.btn:active {
  outline-color: #f8f9ff !important;
  border: 1px solid #f8f9ff !important;
}

.icon {
  width: 1.8em;
}

.deleteIcon {
  width: 1.5em !important;
  padding-bottom: 0.7em !important;
}

.cardFooter {
  position: absolute;
  top: -0.65em;
  right: -0.5em;
  margin-right: -2em;
  transition: 0.5s;
  opacity: 0;
  margin: 0;
  margin-top: 1em;
  padding: 0;
  background-color: #f8f8f800;
  border: 0;
}

.cardFooter:hover {
  opacity: 1;
  z-index: 99;
}

.clear {
  margin: 0;
  padding: 0;
}
