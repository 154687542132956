.pullLeft5 {
  padding-left: 0.5em;
}

.pullDown {
  margin-top: 0.4em;
}

.pushLeft {
  margin-left: 0.1em;
}

.error {
  margin-bottom: 1em;
  color: red;
}
