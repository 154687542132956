.login {
  top: 50px;
  max-width: 350px;
  margin: 0 auto 20px;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;

  /* From https://css.glass */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.title {
  font-size: small;
}

.button {
  padding-top: 15px;
  margin-top: 15px;
}

.clear {
  padding: 0;
  margin: 0;
}
