.pullLeft5 {
  padding-left: 0.5em;
}

.pullDown {
  margin-top: 0.4em;
}

.error {
  margin-bottom: 1em;
  color: red;
}

.btn {
  padding: 0px;
}

.deleteIcon {
  padding-bottom: 0.6em;
}

.newIndicator {
  border-left: 1px solid black;
}
