.nameOverlayBtn {
  text-align: left;
}

.nameOverlayBtn:active .nameOverlayBtn:focus {
  outline: none !important; /* Wichtig, um die Standard-Bootstrap-Stile zu überschreiben */
  box-shadow: none !important; /* Falls ein Box-Schatten vorhanden ist, entfernen Sie ihn ebenfalls */
}

.img {
  height: 100%;
  object-fit: contain;
  width: 100%;
  position: relative;
  left: -1em;
}
