.itemHeader {
  cursor: pointer;
  border-right: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  width: 4em;
  height: 3em;
  margin-left: 0;
  margin-right: 0;
}

.itemHeader:first-child {
  border-left: 1px solid #d2d2d2;
}

.past {
  opacity: 0.35;
  text-decoration: line-through;
}

.seasonIndicatorPeak {
  border-top: 1px solid rgb(221, 113, 113);
}

.seasonIndicatorHight {
  border-top: 1px solid rgb(255, 200, 0);
}

.seasonIndicatorMiddle {
  border-top: 1px solid rgb(17, 255, 0);
}

.seasonIndicatorLow {
  border-top: 1px solid rgb(115, 213, 249);
}

.bookingHover {
  color: black;

  position: absolute;
  width: 20em;
  border: 1px solid white;
  margin-top: 0.6em;
  border-radius: 10px;
}

.pushDown1em {
  margin-bottom: 1em;
}

.pushDown05em {
  margin-bottom: 0.5em;
}

.pullLeft1em {
  margin-left: 1em;
}

.iconAdult {
  width: 1.5em;
  margin-top: -0.2em;
  margin-left: -0.8em;
}

.iconChild {
  width: 0.9em;
  margin-top: -0.2em;
}

.iconPet {
  width: 1.5em;
  margin-top: -0.2em;
}

.iconMessage {
  width: 1.5em;
  margin-top: -0.2em;
}

.clear {
  margin: 0;
  padding: 0;
}

.disabled {
  background-color: #b4b4b4;
  border: 1px solid rgb(97, 97, 97);
  opacity: 0.35;
}
.available {
  background-color: #9ad3bc;
}
.reserved {
  background-color: #ffd7b5;
}
.confirmed {
  background-color: #d7e56f;
}
.canceled {
  background-color: #a6a1a1;
}
.inUse {
  background-color: #eac0f0;
}
.completed {
  background-color: #c0e0c0;
}
.blocked {
  background-color: #ffcccc;
  border: 1px solid red;
}
.blockedByAdmin {
  background-color: #910000;
  border: 1px solid red;
  color: white;
}
