svg {
  margin-top: 5px;
  width: 30px;
  height: 30px;
}

.navLink {
  border-left: 3px solid white;

  color: rgba(0, 0, 0, 0.75);
  background-color: white;
}

.navLink:hover {
  border-left: 3px solid rgba(0, 0, 0, 0.75);

  color: white;
  background-color: rgba(0, 0, 0, 0.75);
}

.spacerTop {
  margin-top: 55px;
}

.isActiveNavLink {
  border-left: 3px solid grey;
  background-color: rgba(223, 221, 221, 0.5);
}

.newBookingIndicator {
  position: absolute;
  top: 3.9em;
  left: 0.8em;
  width: 2em;
  height: 2em;
  background-color: #c7f7c7;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 20px;
  text-align: center;
  padding: 0.2em;
}
