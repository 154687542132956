.pullDown25em {
  margin-top: 2.5em;
}

.messageFromUser {
  background-color: rgb(198, 198, 198);
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid rgb(130, 127, 127);
}

.messageFromUs {
  background-color: lightgreen;
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid green;
}

.messageDate {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
}
