.img {
  width: 100%;
  height: 100%;
}

.prevArrow {
  left: 10px;
}

.nextArrow {
  right: 10px;
}

.centerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow {
  background-color: rgb(255, 255, 255);
  width: 2em;
  height: 2.5em;
  position: relative;
  z-index: 2;
  margin-top: 5em;
  opacity: 0;
  border-radius: 20px;
  border: 1px solid;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.slider:hover > .arrow {
  opacity: 0.8;
}
