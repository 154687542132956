.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  margin-top: -1em;
  margin-bottom: 2em;
}

.removeBorder {
  border: none;
  outline: none;
}

.removeBorder:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
