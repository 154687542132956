:root {
  --toastify-toast-width: 620px;
}

.corrID {
  font-size: 0.6em;
  text-align: right;
  margin-top: 0.5em;
}

.message {
  border-bottom: 1px solid rgba(245, 222, 179, 0.279);
}
