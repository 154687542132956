.card {
  margin: 0.5em;
  transition: 0.5s;
  width: 23rem;
  height: 100%;
}

.cardHeader {
  background-color: white;
  text-align: center;
  height: 4em;
}

.container {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.card:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.removeBorder {
  border: none;
  outline: none;
}

.removeBorder:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.cardAddPicture {
  margin: 0.5em;
  width: 23rem;
  height: 100%;
  opacity: 0.1;
  transition: 0.5s;
}
.cardAddPicture:hover {
  opacity: 1;
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.clearPadding {
  padding: 0;
}

.img {
  border-radius: unset;
  width: 100%;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.icon {
  height: 0.85em;
  position: relative;
  left: -0.5em;
  bottom: 0.3em;
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardBody {
  height: 12.1em;
}
