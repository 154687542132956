.pullLeft5 {
  padding-left: 0.5em;
}

.textArea {
  margin-top: -1em;
  border-top: none;
  border-radius: 0;
}

.error {
  margin-bottom: 1em;
  color: red;
}

.pullDown04em {
  margin-top: 0.4em;
}

.pullDown25em {
  margin-top: 2.5em;
}

.pushDown25em {
  margin-bottom: 2.5em;
}

.pullRight21em {
  padding-left: 2.1em;
}

.icon {
  margin-top: -0.1em;
  padding-left: 2em;
}

.messageFromUser {
  background-color: rgb(198, 198, 198);
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid rgb(130, 127, 127);
}

.messageFromUs {
  background-color: lightgreen;
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid green;
}

.messageDate {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
}

.scrollContainer {
  width: 100%; /* Set the width of the container */
  white-space: nowrap; /* Prevent child elements from wrapping to the next line */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #dee2e6;
}

.scrollContainerChild {
  display: inline-block; /* Display child elements in a row */
}

.doneIcon {
  position: relative;
  z-index: 2;
  left: 1.85em;
  top: 2.4em;

  fill: green;
  background-color: #b8f2b8;
  border-top: 1px solid;
  border-right: 1px solid;
}

.grayedOut {
  filter: grayscale(1);
}

@keyframes bounceToLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-20px);
  }
  60% {
    transform: translateX(0);
  }
}
.isActive {
  animation: bounceToLeft 1s ease 1; /* Apply the bounce animation when this class is added */
}

.done {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.progressBar {
  height: 5px;
}

.progressBar > div {
  height: 5px;
  background-color: black;
}
