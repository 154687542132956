.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.spaceLeft {
  margin-left: 15px;
}

.removeBorder {
  border: none;
  outline: none;
}
