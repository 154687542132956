.component {
  margin-top: 5em;

  height: 100vh;
}

.component > h2 {
  margin-left: 15px;
}

.content {
  margin-right: 15px;
}

.bottomSpacer {
  padding-bottom: 25px;
}

.pullRightHeadlineContent {
  margin-left: 1.7em;
  margin-bottom: 1em;
}

.pullUpHeadlineContent {
  margin-top: -0.7em;
}

.headLine {
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: 2em;
  border-bottom: 1px solid #d2d2d2;
}
