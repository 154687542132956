.headerHidden {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.iconCollection {
  margin-right: -2.2em;
  margin-top: -1.6em;
}

.icon {
  height: 2em;
  width: 2em;
  margin-top: -1.5em;
  margin-right: -2em;
}

.icon:hover {
  border: 1px solid rgba(0, 0, 0, 0);
}

.mainPicture {
  border: 2px solid black;
}

.mainPictureFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0px;
  background-color: rgba(255, 255, 255, 0);
}

.card {
  width: 20.1rem;
}
.card:hover .headerHidden {
  opacity: 1;
}

.cardAddPicture {
  width: 20.1rem;
  transition: 0.5s;
  opacity: 0.3;
}

.cardAddPicture:hover {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.adjustBadge {
  position: absolute;
  left: -0.2em;
}

.noImage {
  border: 1px dashed black;
  margin-bottom: 1em;
  opacity: 0.35;
}
