.component {
  margin-top: 5em;

  height: 100vh;
}

.component > h2 {
  margin-left: 15px;
}

.content {
  margin-right: 15px;
}

.bottomSpacer {
  padding-bottom: 25px;
}

.pullRightHeadlineContent {
  margin-left: 1.7em;
  margin-bottom: 1em;
}

.pullUpHeadlineContent {
  margin-top: -0.7em;
}

.headLine {
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: 2em;
  border-bottom: 1px solid #d2d2d2;
}

.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  margin-top: -1em;
  margin-bottom: 2em;
}

.removeBorder {
  border: none;
  outline: none;
}

.removeBorder:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.bg {
  background-image: url("../../public/bg2.jpg"); /* Path is relative to the public folder */
  background-size: cover; /* Cover the entire container, maintaining aspect ratio */
  background-position: center; /* Center the image within the container */
  background-repeat: no-repeat; /* Do not repeat the background image */
  height: 100vh; /* Set the height of the container to the full viewport height */
  width: 100vw; /* Set the width of the container to the full viewport width */
}

.glas_card {
  top: 50px;
  max-width: 350px;
  margin: 0 auto 20px;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.48);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 1.1);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(0, 0, 0, 0);
}

.glas_form {
  border: none;
  outline: none;
  box-shadow: none;
  background: #0000001f;
}

.glas_form:focus {
  outline: none;
  border: none;
  box-shadow: none;
  background: #00000040;
}

.errorTextStyle {
  color: #dc3545;
  margin-top: -0.75rem;
  margin-bottom: 0.75em;
  font-size: 0.875em;
}
