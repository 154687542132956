.pullLeft5 {
  padding-left: 0.5em;
}

.textArea {
  margin-top: -1em;
  border-top: none;
  border-radius: 0;
}

.error {
  margin-bottom: 1em;
  color: red;
}

.pullDown04em {
  margin-top: 0.4em;
}

.pullDown25em {
  margin-top: 2.5em;
}

.pushDown25em {
  margin-bottom: 2.5em;
}

.pullRight21em {
  padding-left: 2.1em;
}

.icon {
  margin-top: -0.1em;
  padding-left: 2em;
}

.messageFromUser {
  background-color: rgb(198, 198, 198);
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid rgb(130, 127, 127);
}

.messageFromUs {
  background-color: lightgreen;
  border-radius: 10px;
  margin-top: 0.5em;
  padding-bottom: 1em;
  border: 1px solid green;
}

.messageDate {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5em;
}
