.btnSwitch {
  margin-top: 3em;
}

.icon {
  margin-top: 0px;
}

.content {
  border: 1px solid #626465;
  border-radius: 5px;
  padding-top: 1em;
  padding-bottom: 1em;
}

.pushRight {
  margin-right: 0.5em;
}

.pushDown {
  margin-bottom: 1em;
}

.pullLeft {
  padding-left: 0px;
}
