.headerHidden {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: none;
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .headerHidden {
  opacity: 1;
}

.iconCollection {
  margin-right: -1.8em;
  width: 2.6em;
  background-color: white;
  border-radius: 0 5px 0 20px;
}

.icon {
  width: 2.5em;
  margin-top: 1em;
}

.icon:hover {
  scale: 1.1;
}

.mainPicture {
  border: 2px solid black;
}

.mainPictureFooter {
  background-color: white !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0px;
  background-color: rgba(255, 255, 255, 0);
}

.card {
  margin: 0.8em;
  width: 31rem;
  transition: 0.5s;
  padding: 0;
}
.card:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.cardAddPicture {
  margin: 0.5em;
  width: 17rem;
  opacity: 0.1;
  transition: 0.5s;
}
.cardAddPicture:hover {
  opacity: 1;
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.adjustBadge {
  border-radius: 0;
  position: absolute;
  left: -0.1em;
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img {
  width: 30.9rem;
  height: 15em;
}
