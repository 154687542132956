.badge {
  margin-right: 1em;
  border: 1px solid rgb(162, 158, 158);
  margin-bottom: 0.3em;
  cursor: pointer;
}

.badge:hover {
  border: 1px solid black;
}

.container {
  padding: 0;
  padding-bottom: 1em;
}

.listItem {
  list-style: none;
}
