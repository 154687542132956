.topLine {
  border-top: 1px solid black;
}

.fixCost {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 0.3em;
  padding-left: 1em;
  margin-bottom: 1em;
  margin-left: -1.2em;
  background: aliceblue;
}
