/* ------ */

.horizontalList {
  list-style: none;
  padding: 0;
}

.removeBullets li {
  display: inline-block;
  padding-right: 1.5em;
  padding-top: 1em;
}

.svg {
  margin-top: -0.5em;
  margin-right: 0.7em;
  padding-bottom: 0.8em;
}
