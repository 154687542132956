.component {
  -webkit-box-shadow: 0px 8px 14px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 8px 14px 2px rgba(0, 0, 0, 0.4);
}

.fillRemainingSpace {
  min-height: 100vh;
}

.centerComponent {
  padding-left: 50px;
  padding-top: 10px;
}
