.price {
  color: #009f00;
}

.button {
  border-radius: 15px;
  margin-bottom: 1em;
}

.button:first-child {
  margin-right: 1.5em;
}
