.item {
  margin: 10px;
  transition: 0.5s;
}

.container {
  margin: 15px auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  box-shadow: 0px 1px 5px #00000061;
}

.item:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.tab {
  margin-top: -1em;
}

.menu {
  margin: 10px auto;
}

.spaceLeft {
  margin-left: 15px;
}

.spacerBottom {
  padding-bottom: 28px;
}

.spacerTop10 {
  margin-top: 1em;
}

.spacerBottom17 {
  padding-bottom: 1.7em;
}

.spacerBottom05 {
  padding-bottom: 0.5em;
}

.spacerBottom10 {
  margin-bottom: 1em;
}

.spacerBottom10 {
  margin-bottom: 0.7em;
}

.pullLeft {
  margin-left: -0.7em;
}

.badge {
  color: black;
  border: 1px solid black;
  margin-left: 5px;
}

.resetTopMargin {
  margin-top: 0;
}

.pullUp05 {
  margin-top: -0.5em;
}

.invitationAccepted {
  background-color: #fafad2 !important;
}
