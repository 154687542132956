.item {
  margin: 10px;
  transition: 0.5s;
}

.container {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  box-shadow: 0px 1px 5px #00000061;

  padding-bottom: 2em;
}

.item:hover {
  transform: scale(1.02);
  z-index: 2;
  box-shadow: 5px 5px 5px #00000061;
}

.tab {
  margin-top: -1em;
  padding-bottom: 2em;
}

.menu {
  margin: 10px auto;
}

.spaceLeft {
  margin-left: 15px;
}

.spacerBottom {
  padding-bottom: 28px;
}

.spacerBottom10 {
  padding-bottom: 10px;
}

.spacerBottom05 {
  padding-bottom: 0.5em;
}

.img {
  max-width: 100vw;
  max-height: 80vh; /* Adjust this value as needed */
  width: auto;
  height: auto;
}

.badge {
  color: black;
  border: 1px solid black;
  margin-right: 5px;
}

.btn {
  padding: 0px;
}

.btn:focus,
.btn:active {
  outline-color: #f8f9ff !important;
  border: 1px solid #f8f9ff !important;
}

.pullUp {
  margin-top: -0.9em;
}

.pullDown {
  margin-top: 1.3em;
}

.card {
  margin: 0.5em;
  width: 17rem;
}
