.textArea {
  margin-top: -1em;
  border-top: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

.textArea:focus {
  margin-top: -1em;
  border-top: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
