.scrollContainer {
  display: flex;
}

.containerItem {
  flex: 1;
  width: 2.5em;
}

.title {
  padding: 0;
  border: 1px solid #d2d2d2;
  border-radius: 20px 0 0 20px;
}

.button {
  border: none;
}

.arrowLeft {
  margin-top: 0.1em;
}

.arrowRight {
  margin-top: 0.1em;
}

.year {
  margin-top: 0.5em;
  width: 10em;
  text-align: center;
}

.datePicker {
  padding: 0.2em;
  border: 1px solid;
}

.pushDown {
  margin-bottom: 1em;
  margin-top: 2em;
}

.disableWrap {
  flex-wrap: nowrap;
}

.nameOverlayBtn {
  text-align: left;
}
