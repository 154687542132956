.customNav {
  display: flex; /* Make the items flex containers */
  flex-direction: column; /* Stack items vertically */
  background-color: white;
  height: 100%;

  position: fixed;
  margin-top: 60px;
  width: 190px;

  -webkit-box-shadow: 6px 0px 14px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 6px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
