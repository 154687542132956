.menu {
  margin: 10px auto;
  box-shadow: 0px 1px 5px #00000061;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  margin-top: -1em;
  margin-bottom: 2em;
}

.spaceLeft {
  margin-left: 15px;
}

.backBtn {
  height: 1.6em;
  margin: 0;
  padding: 0;
}

.deleteIcon {
  height: 1.5em;
  margin: 0;
  padding: 0;
  margin-top: -0.3em;
}

.doneIcon {
  height: 1.4em;
  margin: 0;
  padding: 0;
}
