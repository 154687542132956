.notificationOverlay {
  position: fixed;
  z-index: 99999;
  border-top: 1px solid black;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 5em;
  background-color: white;

  box-shadow: 0 1px 51px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 1px 51px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 1px 51px rgba(0, 0, 0, 0.7);
}

.centeredText {
  text-align: center;
  padding: 0;
  margin: 0;
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(185, 185, 185, 0.85);
  z-index: 9999;
}

.title {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid black;
}
